body {
	background-color: black !important;
	font-family: sourcesanspro-Regular !important;  
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}
html {
	overflow:   scroll;
}
::-webkit-scrollbar {
	width: 0px;
	background: transparent; /* make scrollbar transparent */
}
a {
	text-decoration: none;
}
div.main {
    background-color: black;
    color: white;
}
main {
	padding-bottom: 100px;
}

.fade-enter{
   opacity: 0;
}
.fade-exit{
   opacity: 1;
}
.fade-enter-active{
   opacity: 1;
}
.fade-exit-active{
   opacity: 0;
}
.fade-enter-active,
.fade-exit-active{
   transition: opacity 1000ms;
}


.section {
	background-color: #4EBA6F;
	height: 300px;
	line-height: 300px;
}
.section-3 { background-color: #F15A5A;}

.loader {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 50px;
	height: 50px;
	margin: 0 0 -25px -25px;
	background: rgba(0,0,0,0.5);
}

/*	$Loader Quadrant
	========================================================================== */

.loader-quart {
	border-radius: 50px;
	border: 6px solid rgba(255,255,255,0.4);
}

.loader-quart:after {
	content: '';
	position: absolute;
	top: -6px; 
	left: -6px;
	bottom: -6px;
	right: -6px;
	border-radius: 50px;
	border: 6px solid transparent;
	border-top-color: #fff;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}

/*	$Loader Double circle
	========================================================================== */

.loader-double {
	border-radius: 50px;
	border: 6px solid transparent;
	border-top-color: #fff;
	border-bottom-color: #fff;
	-webkit-animation: spin 1.5s linear infinite;
	animation: spin 1.5s linear infinite;
}

.loader-double:before,
.loader-double:after {
	content: '';
	position: absolute;
	top: 5px; 
	left: 5px;
	bottom: 5px;
	right: 5px;
	border-radius: 50px;
	border: 6px solid transparent;
	border-top-color: #fff;
	border-bottom-color: #fff;
	filter: alpha(opacity=6);
	     -khtml-opacity: .6;
	       -moz-opacity: .6;
	            opacity: .6;
	-webkit-animation: spinreverse 2s linear infinite;
	animation: spinreverse 2s linear infinite;
}

.loader-double:before {
	top: 15px; 
	left: 15px;
	bottom: 15px;
	right: 15px;
	-webkit-animation: spinreverse 3s linear infinite;
	animation: spinreverse 3s linear infinite;
}

/*	$Loader Multiple circle
	========================================================================== */

.loader-circles {
	border-radius: 50px;
	border: 3px solid transparent;
	border-top-color: #fff;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}

.loader-circles:before,
.loader-circles:after {
	content: '';
	position: absolute;
	top: 5px; 
	left: 5px;
	bottom: 5px;
	right: 5px;
	border-radius: 50px;
	border: 3px solid transparent;
	border-top-color: #fff;
	filter: alpha(opacity=8);
	     -khtml-opacity: .8;
	       -moz-opacity: .8;
	            opacity: .8;
	-webkit-animation: spinreverse 5s linear infinite;
	animation: spinreverse 5s linear infinite;
}

.loader-circles:before {
	top: 12px; 
	left: 12px;
	bottom: 12px;
	right: 12px;
	-webkit-animation: spinreverse 10s linear infinite;
	animation: spinreverse 10s linear infinite;
}

/*	$Loader Bars
	========================================================================== */

.loader-bars:before,
.loader-bars:after,
.loader-bars span {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 10px;
	height: 30px;
	background-color: #fff;
	-webkit-animation: grow 1.5s linear infinite;
	animation: grow 1.5s linear infinite;
}

.loader-bars:after {
	left: 15px;
	-webkit-animation: grow 1.5s linear -.5s infinite;
	animation: grow 1.5s linear -.5s infinite;
}

.loader-bars span {
	left: 30px;
	-webkit-animation: grow 1.5s linear -1s infinite;
	animation: grow 1.5s linear -1s infinite;
}

@-webkit-keyframes grow {
	0% { -webkit-transform: scaleY(0); transform: scaleY(0); opacity: 0;}
	50% { -webkit-transform: scaleY(1); transform: scaleY(1); opacity: 1;}
	100% { -webkit-transform: scaleY(0); transform: scaleY(0); opacity: 0;}
}

@keyframes grow {
	0% { -webkit-transform: scaleY(0); transform: scaleY(0); opacity: 0;}
	50% { -webkit-transform: scaleY(1); transform: scaleY(1); opacity: 1;}
	100% { -webkit-transform: scaleY(0); transform: scaleY(0); opacity: 0;}
}

@-webkit-keyframes spin {
  	0%{ -webkit-transform: rotate(0deg); tranform: rotate(0deg);}
	100%{ -webkit-transform: rotate(360deg); tranform: rotate(360deg);}
}

@keyframes spin {
	0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg);}
	100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg);}
}

@-webkit-keyframes spinreverse {
  	0%{ -webkit-transform: rotate(0deg); tranform: rotate(0deg);}
	100%{ -webkit-transform: rotate(-360deg); tranform: rotate(-360deg);}
}

@keyframes spinreverse {
	0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg);}
	100%{ -webkit-transform: rotate(-360deg); transform: rotate(-360deg);}
}

/**********PROFILE**********/
.profile_grid {
	display: flex;
	flex-direction: column;
	margin-top: 48px;
}
.profile_grid .avatar {
	width: 40vw;
	height: 40vw;

}
.profile_grid p {
	font-size: 1.25rem;
	font-weight: bold;
	margin-top: 16px;
	text-transform: uppercase;
}
.profile_grid button {
	color: white;
	font-size: 1rem;
}
.profile_grid button:hover,
.profile_grid button:active {
	text-decoration: underline;
}

.login_message {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60vh;
}
.login_message a {
	padding: 0 6px;
	color: #F89613;
}

/************ Filter **************/
.filterIcon {
	position: absolute;
	right:0;
	top:35px;
}

/************* Tickets **************/
.stamp {
	transform: rotate(12deg);
	color: #555;
	font-size: 3rem;
	font-weight: 700;
	border: 0.25rem solid #555;
	display: inline-block;
	padding: 0.25rem 1rem;
	text-transform: uppercase;
	border-radius: 1rem;
	font-family: 'Courier';
}

.is-nope {
	color: #D23;
	border: 0.5rem double #D23;
	transform: rotate(-10deg);
	font-size: 2rem;  
	background: rgba(255,255,255,0.7);
}

.is-success {
	color: #4caf50;
	border: 0.5rem double #4caf50;
	transform: rotate(-10deg);
	font-size: 2rem;  
	background: rgba(255,255,255,0.7);
}
