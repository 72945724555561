@font-face {
  font-family: 'gilroy-bold';
  src:  url('../assets/fonts/Gilroy/Gilroy-Bold.ttf');
}
@font-face {
  font-family: 'gilroy-extrabold';
  src:  url('../assets/fonts/Gilroy/Gilroy-ExtraBold.otf');
}
@font-face {
  font-family: 'gilroy-heavy';
  src:  url('../assets/fonts/Gilroy/Gilroy-Heavy.ttf');
}
@font-face {
  font-family: 'gilroy-light';
  src:  url('../assets/fonts/Gilroy/Gilroy-Light.ttf');
}
@font-face {
  font-family: 'gilroy-medium';
  src:  url('../assets/fonts/Gilroy/Gilroy-Medium.ttf');
}
@font-face {
  font-family: 'gilroy-regular';
  src:  url('../assets/fonts/Gilroy/Gilroy-Regular.ttf');
}


@font-face {
  font-family: 'sourcesanspro-Black';
  src:  url('../assets/fonts/SourceSansPro/SourceSansPro-Black.ttf');
}
@font-face {
  font-family: 'sourcesanspro-BlackItalic';
  src:  url('../assets/fonts/SourceSansPro/SourceSansPro-BlackItalic.ttf');
}
@font-face {
  font-family: 'sourcesanspro-Bold';
  src:  url('../assets/fonts/SourceSansPro/SourceSansPro-Bold.ttf');
}
@font-face {
  font-family: 'sourcesanspro-BoldItalic';
  src:  url('../assets/fonts/SourceSansPro/SourceSansPro-BoldItalic.ttf');
}
@font-face {
  font-family: 'sourcesanspro-ExtraLight';
  src:  url('../assets/fonts/SourceSansPro/SourceSansPro-ExtraLight.ttf');
}
@font-face {
  font-family: 'sourcesanspro-ExtraLightItalic';
  src:  url('../assets/fonts/SourceSansPro/SourceSansPro-ExtraLightItalic.ttf');
}
@font-face {
  font-family: 'sourcesanspro-Italic';
  src:  url('../assets/fonts/SourceSansPro/SourceSansPro-Italic.ttf');
}
@font-face {
  font-family: 'sourcesanspro-Light';
  src:  url('../assets/fonts/SourceSansPro/SourceSansPro-Light.ttf');
}
@font-face {
  font-family: 'sourcesanspro-LightItalic';
  src:  url('../assets/fonts/SourceSansPro/SourceSansPro-LightItalic.ttf');
}
@font-face {
  font-family: 'sourcesanspro-Regular';
  src:  url('../assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf');
}
@font-face {
  font-family: 'sourcesanspro-Semibold';
  src:  url('../assets/fonts/SourceSansPro/SourceSansPro-Semibold.ttf');
}
@font-face {
  font-family: 'sourcesanspro-SemiboldItalic';
  src:  url('../assets/fonts/SourceSansPro/SourceSansPro-SemiboldItalic.ttf');
}

@font-face {
  font-family: 'icomoon';
  src:  url('../assets/fonts/icomoon.eot?ee19s3');
  src:  url('../assets/fonts/icomoon.eot?ee19s3#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon.ttf?ee19s3') format('truetype'),
    url('../assets/fonts/icomoon.woff?ee19s3') format('woff'),
    url('../assets/fonts/icomoon.svg?ee19s3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Icons_About:before {
  content: "\e900";
  color: #fff;
}
.icon-Icons_Add:before {
  content: "\e901";
  color: #fff;
}
.icon-Icons_Bars:before {
  content: "\e902";
  color: #fff;
}
.icon-Icons_Block:before {
  content: "\e903";
  color: #fff;
}
.icon-Icons_Breweries:before {
  content: "\e904";
  color: #fff;
}
.icon-Icons_Close:before {
  content: "\e905";
  color: #fff;
}
.icon-Icons_Contact:before {
  content: "\e906";
  color: #fff;
}
.icon-Icons_Deals:before {
  content: "\e907";
}
.icon-Icons_Enter-Submit:before {
  content: "\e908";
  color: #fff;
}
.icon-Icons_Favourite:before {
  content: "\e909";
  color: #fff;
}
.icon-Icons_Filter:before {
  content: "\e90a";
  color: #fff;
}
.icon-Icons_Go-To:before {
  content: "\e90b";
  color: #fff;
}
.icon-Icons_Help:before {
  content: "\e90c";
  color: #fff;
}
.icon-Icons_Location:before {
  content: "\e90d";
  color: #fff;
}
.icon-Icons_Login-Profile:before {
  content: "\e90e";
  color: #fff;
}
.icon-Icons_Menu:before {
  content: "\e90f";
  color: #fff;
}
.icon-Icons_Merchants:before {
  content: "\e910";

}
.icon-Icons_Nightclubs:before {
  content: "\e911";
  color: #fff;
}
.icon-Icons_Ratings:before {
  content: "\e912";
  color: #fff;
}
.icon-Icons_Restaurants:before {
  content: "\e913";
  color: #fff;
}
.icon-Icons_Shop:before {
  content: "\e914";
  color: #fff;
}
.icon-Icons_Tags-Form-Factors:before {
  content: "\e915";
  color: #fff;
}
.icon-Icons_Top-up:before {
  content: "\e916";

}
.icon-Icons_Transactions:before {
  content: "\e917";
}
.icon-Icons_View-More:before {
  content: "\e918";
  color: #fff;
}
.icon-Icons_Rupee:before {
  content: "\e922";
  color: #fff;
}
.icon-Icons_Activated:before {
  content: "\e919";
  color: #fff;
}
.icon-Icons_Bracelet:before {
  content: "\e91a";
  color: #fff;
}
.icon-Icons_Delivered:before {
  content: "\e91b";
  color: #fff;
}
.icon-Icons_Dispatched:before {
  content: "\e91c";
  color: #fff;
}
.icon-Icons_Jewellery:before {
  content: "\e91d";
  color: #fff;
}
.icon-Icons_Keychain:before {
  content: "\e91e";
  color: #fff;
}
.icon-Icons_Order-Confirmed:before {
  content: "\e91f";
  color: #fff;
}
.icon-Icons_Rings:before {
  content: "\e920";
  color: #fff;
}
.icon-Icons_Sticker:before {
  content: "\e921";
  color: #fff;
}
.gly-rotate-45 {
  filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

